$(function (){
	'use strict';
	
	/* Navigation */
	var navigation = function (navi_icon, nav_menu, backdrop_nav) { 
		var addIcon = false;
		if ($(navi_icon).length < 1) {
			console.log('add new icon menu');
			var addIcon = true;
		}  

		if ($(nav_menu).length < 1) {
			console.log("nav_menu not found");
			return;
		}
		else {
			nav_menu = nav_menu; 
		}
		// check has child submenu
		function check_sub(navi_class){
			if (!navi_class) {
				var navi_class  = ".navigation";
			}
			var submenu = $(navi_class + ' ul li').has('ul');
			var icon = 'sub-icon';
			submenu.addClass('hasSub');
			submenu.append('<span class="'+icon+'">+</span>')
			// $('.hasSub > ul').addClass('animated slideInDown');
			var sub_icon = $('.'+icon); 

			if ($('.megachild').length) { 
				var submenu = $('.'+navi_class + ' ul>li').has('ul.megachild');
				if (!submenu.hasClass('hasmegachild')) {
					submenu.addClass('hasmegachild');
				}
			}
			else if ($('.hasmegachild').length) { 
				var submenu = $('.'+navi_class + ' ul>li.hasmegachild');
				$('.'+navi_class + ' ul>li.hasmegachild > ul').addClass('megachild');
			}

			sub_icon.click(function(){
				if ($(this).hasClass('on')){
					$(this).removeClass('on');
					$(this).text('+');
					$(this).parent().removeClass('hasSub_on');
				}
				else if (!$(this).hasClass('on')){
					$(this).addClass('on');
					$(this).text('-');
					$(this).parent().addClass('hasSub_on');
				}
			});
		} 
		check_sub(nav_menu);
		/* add backdrop_nav */
		$(nav_menu).prepend('<div class="'+backdrop_nav.replace(/\.|\#/g,'')+'"></div>');
		var backdrop = $('.backdrop_nav');
		if (!$(nav_menu).hasClass('nav-open')){
			backdrop.hide();
		}
		else {
			backdrop.show();
		}

		var nav_toggle = function (nav_menu) {
			if (!$(nav_menu).hasClass('nav-open')){
				$(nav_menu).addClass('nav-open');
				backdrop.show(); 
			}
			else {
				$(nav_menu).removeClass('nav-open');
				backdrop.hide(); 
			}
		}; 
		if (addIcon) { 
			var nav_icon_text  = '<span class="'+navi_icon.replace(/\.|\#/g,'')+'"><span></span></span>';
			$(nav_menu).prepend(nav_icon_text);
			navi_icon = $(navi_icon);
		}
		navi_icon.click(function (){
			nav_toggle($(nav_menu));  
		});
		backdrop.click(function (){
			nav_toggle($(nav_menu));  
		});
	};

	navigation('.navigation_icon', '.navigation', 'backdrop_nav');


	/* FIX TOP */
	var fixtop = function (topfix){
		var top;
		if (topfix === undefined) {
			top =$('.top');
		}
		else {
			top = topfix;
		}
		var topPosition = top.offset().top;

		var fixed = function() {
			if (!top.hasClass('isfixed')) {
				top.addClass('isfixed');
				$('body').addClass('topfixed');
			}
		};
		var removefixed = function () {
			if (top.hasClass('isfixed')) {
				top.removeClass('isfixed');
				$('body').removeClass('topfixed');
			}
		};

		$(window).on('scroll',function() {
			if($(window).width()>768){
				if($(window).scrollTop() > topPosition ) { 
					fixed();
				}
				else { 
					removefixed();
				}
			}
			else { 
				removefixed();
			}
		});
	};
	fixtop($('.header-top'));
});