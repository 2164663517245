var navigation = require('./navigation');

(function($) {
    /*// *** $(".accordion").fqaAccordion('.heading', '.content', "active-colapsed"); ***/
    $.fn.fqaAccordion = function (heading, body, activeClass) { 
        var accordion = $(this); // Cache element
        accordion.hide().fadeIn(); // Fade in on load
        accordion.find(body).hide(); 
        if (accordion.find("."+activeClass+"").lengt > 0)
            accordion.find("."+activeClass+"").next(body).show(); // Open "+activeClass+" panel
        else accordion.find(heading).first().addClass(activeClass).next(body).show();
        accordion.find(heading).on("click", function (e) { // Listen to onClick
            e.preventDefault();  
            var current = $(this).next(body); // Cache current
            var currenHeading = $(this).closest(heading);
            if (current.is(":hidden")) { // Check if not "+activeClass+" 
                accordion.find(heading).removeClass(activeClass);
                currenHeading.addClass(activeClass);
                accordion.find(body).slideUp(); // Open curren panel
                current.slideDown();
            } 
        });        
    };
})(jQuery);

(function($){
    $('.slider .owl-carousel').owlCarousel({
        loop:true,
        margin:0,
        responsiveClass:true,
        dots: true,
        dotsClass: 'container owl-dots',
        items:1,
        nav:false
    }); 
    $('.breaking-new-wrapper .owl-carousel').owlCarousel({
        loop:true,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:false,
        margin:0,
        responsiveClass:true,
        dots: true,
        items:1,
        nav:true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
    }); 
    $('.sliderShowCase .owl-carousel').owlCarousel({
        loop:true,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:false,
        margin:0,
        responsiveClass:true,
        dots: false,
        responsive : {
            0 : {
                items : 2,
                navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
            },
            // breakpoint from 768 up
            768 : {
                items : 4,
                navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
            },
            1024 : {
                items : 5,
            }
        },
        nav:true,
        navText: [" ", " "]
    });  
    $('.slider-banner .owl-carousel').owlCarousel({
        loop:true,
        margin:0,
        responsiveClass:true,
        dots: true,
        dotsClass: 'owl-dots',
        items:1,
        nav:false
    });  
    $('.tab-nav >li>a').on('click', function (e) {
        e.preventDefault();
    })

    $('body').append('<a class="backtotop" href="#0"><i class="fa fa-chevron-up"></i></a>');   
    // browser window scroll (in pixels) after which the "back to top" link is shown
    var offset = 300,
        //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
        offset_opacity = 1200,
        //duration of the top scrolling animation (in ms)
        scroll_top_duration = 700,
        //grab the "back to top" link
        $back_to_top = $('.backtotop');
 
    $(window).scroll(function(){
        ( $(this).scrollTop() > offset ) ? $back_to_top.addClass('is-visible') : $back_to_top.removeClass('is-visible fade-out');
        if( $(this).scrollTop() > offset_opacity ) { 
        $back_to_top.addClass('fade-out');
        }
    });
 
    $back_to_top.on('click', function(event){
        event.preventDefault();
        $('body,html').animate({
        scrollTop: 0 ,
        }, scroll_top_duration 
        );
    });    
 
    $(".accordion").fqaAccordion('.heading', '.content', "active-colapsed");   
})(jQuery);

if ($('.toggleArea').length) { 
    var closestEl = 'ul';
    var toggleArea = $('.toggleArea');
    var resetAllToggle = function () { 
        $('.toggleArea').each(function () {
            if (!$(this).hasClass('currentToggle')) {
                $(this).removeClass('open'); 
                var oldText = $(this).find('.icon-toggle-text').text();
                $(this).find('.icon-toggle-text').text(oldText.replace('-','+'));
                var toogleId =  $(this).attr('data-toggle');
                var classToogle = '.' + toogleId;
                $(classToogle).removeClass('active');

                $(this).closest(closestEl).removeClass('focus');
            }
        })    		
    }; 

    toggleArea.click(function() {
        $(this).addClass('currentToggle');
        resetAllToggle();
        var toogleId =  $(this).attr('data-toggle');
        var classToogle = '.' + toogleId; 

        if (!$(this).hasClass('justOpen')) {
            $(this).toggleClass('open');
            $(classToogle).toggleClass('active');
        }
        else {
            $(this).addClass('open');
            $(classToogle).addClass('active');
        }

        if ( $(this).hasClass('open') ) {
            var oldText = $(this).find('.icon-toggle-text').text();
            $(this).find('.icon-toggle-text').text(oldText.replace('+','-')); 
            
            $(this).closest(closestEl).addClass('focus');
        }
        else  {
            var oldText = $(this).find('.icon-toggle-text').text();
                $(this).find('.icon-toggle-text').text(oldText.replace('-','+')); 
            // }
            
            $(this).closest(closestEl).removeClass('focus');
        }     

        $(this).removeClass('currentToggle');
    })
}
